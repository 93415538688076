import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { DealContactsService } from 'src/app/deals/card/deal-contacts/core/deal-contacts.service';
import { ClientContactsToolbarComponent } from 'src/app/clients/card/client-contact/toolbar/client-contacts-toolbar.component';

@Component({
  selector: 'tmt-deal-contacts-toolbar',
  templateUrl: './deal-contacts-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealContactsToolbarComponent extends ClientContactsToolbarComponent {
  constructor(
    gridService: GridService,
    cdr: ChangeDetectorRef,
    private readonly dealContactsService: DealContactsService,
  ) {
    super(gridService, cdr, dealContactsService);
  }
}
