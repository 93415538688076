import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const DEALS_LIST: List = {
  dataCollection: 'Deals',
  version: 2,
  name: 'deal',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 160,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 160,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 125,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organization',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'manager',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'resolution',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'source',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      state: 'deal.profile',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
      type: GridColumnType.Navigation,
    },
    {
      name: 'created',
      header: 'shared2.props.created.value',
      hint: 'shared2.props.created.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'modified',
      header: 'shared2.props.modified.value',
      hint: 'shared2.props.modified.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'isActive',
      header: 'shared2.props.isActive.short',
      hint: 'shared2.props.isActive.value',
      type: GridColumnType.Boolean,
      fixedWidth: true,
    },
    {
      name: 'createdBy',
      header: 'shared2.props.createdBy.value',
      hint: 'shared2.props.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared2.props.modifiedBy.value',
      hint: 'shared2.props.modifiedBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'organization',
      header: 'shared2.props.organization',
      hint: 'shared2.props.organization',
      type: GridColumnType.String,
    },
    {
      name: 'description',
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
      type: GridColumnType.String,
    },
    {
      name: 'manager',
      header: 'shared2.props.manager',
      hint: 'shared2.props.manager',
      type: GridColumnType.String,
    },
    {
      name: 'resolution',
      header: 'shared2.props.resolution',
      hint: 'shared2.props.resolution',
      type: GridColumnType.String,
    },
    {
      name: 'source',
      header: 'shared2.props.source',
      hint: 'shared2.props.source',
      type: GridColumnType.String,
    },
    {
      name: 'state',
      header: 'shared2.props.state',
      hint: 'shared2.props.state',
      type: GridColumnType.State,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organization',
      field: 'organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'manager',
      field: 'manager.email',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resolution',
      field: 'resolution.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'source',
      field: 'source.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
