import { inject, Inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';

import { Exception } from 'src/app/shared/models/exception';
import { ContactCreateModalComponent } from 'src/app/contacts/contact-create/contact-create.component';
import { DealContactsToolbarComponent } from 'src/app/deals/card/deal-contacts/toolbar/deal-contacts-toolbar.component';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ClientContactsService } from 'src/app/clients/card/client-contact/core/client-contacts.service';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { DealContact } from 'src/app/deals/model/deal.model';

@Injectable()
export class DealContactsService extends ClientContactsService {
  public override gridOptions: GridOptions = {
    resizableColumns: true,
    sorting: true,
    selectionType: SelectionType.row,
    toolbar: DealContactsToolbarComponent,
    commands: [
      {
        name: 'create',
        allowedFn: () => !this.readonlyDeal() && !this.readonly(),
        handlerFn: () => {
          this.createClientContact();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonlyDeal() && !!formGroup,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteContact(formGroup.value.id);
        },
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonlyDeal(),
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteContact(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  private readonlyDeal = signal<boolean>(false);
  private readonly actionPanelService = inject(ActionPanelService);
  private readonly dealCardService = inject(DealCardService);

  constructor(
    @Inject('organizationId') public override organizationId: string,
  ) {
    super(organizationId);
    this.readonlyDeal.set(!this.dealCardService.deal.editAllowed);
    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load();
    });
  }

  /** Loads client contacts which mentioned in deal. */
  public override load(): void {
    if (!this.dealCardService.deal?.dealContacts?.length) {
      return;
    }

    const queryExtras = {
      filter: {
        id: {
          in: this.dealCardService.deal.dealContacts.map((dealContact) => ({
            type: 'guid',
            value: dealContact.contactId,
          })),
        },
      },
    };

    super.load(queryExtras);
  }

  /** Adding a new row to the contacts table. */
  protected override createClientContact(): void {
    const modalRef = this.modal.open(ContactCreateModalComponent, {
      injector: this.injector,
    });

    (modalRef.componentInstance as ContactCreateModalComponent).organizationId =
      this.organizationId;

    modalRef.result.then(
      (result) => {
        this.addContactToDeal(result.id);
      },
      () => null,
    );
  }

  /**
   * Deletes contact.
   *
   * @param contactId id of deleting contact.
   */
  protected override deleteContact(contactId: string): void {
    const dealContacts = this.dealCardService.deal.dealContacts;
    if (!dealContacts?.length) {
      return;
    }

    const index = dealContacts.findIndex(
      (dealContact) => dealContact.contactId === contactId,
    );

    if (index === -1) {
      return;
    }

    dealContacts.splice(index, 1);
    this.dealCardService.dealCollection
      .entity(this.dealCardService.deal.id)
      .patch({ dealContacts })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.onContactDeleted(contactId);
          this.notificationService.successLocal(
            'components.dealContactsService.messages.contactDeleted',
          );
        },
        error: (error: Exception) =>
          this.notificationService.error(error.message),
      });
  }

  /**
   * Handles post-deletion logic for a contact.
   *
   * @param contactId id of deleting contact.
   */
  protected override onContactDeleted(contactId: string): void {
    super.onContactDeleted(contactId);

    this.dealCardService.deal.dealContacts =
      this.dealCardService.deal.dealContacts.filter(
        (c) => c.contactId !== contactId,
      );
  }

  /**
   * Adds contact to deal.
   *
   * @param contactId id of adding contact.
   */
  private addContactToDeal(contactId: string): void {
    const updatedContacts: DealContact[] = [];
    if (this.dealCardService.deal.dealContacts?.length) {
      const existingContacts = <DealContact[]>(
        this.dealCardService.deal.dealContacts.map((c) => ({
          contactId: c.contactId,
        }))
      );
      updatedContacts.push(...existingContacts);
      updatedContacts.push(<DealContact>{ contactId });
    } else {
      updatedContacts.push(<DealContact>{ contactId });
    }

    this.dealCardService.dealCollection
      .entity(this.dealCardService.deal.id)
      .patch({ dealContacts: updatedContacts })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.dealCardService.deal.dealContacts = updatedContacts;
          this.notificationService.successLocal(
            'components.dealContactsService.messages.contactAdded',
          );
          this.load();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }
}
