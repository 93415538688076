import {
  DestroyRef,
  Inject,
  Injectable,
  computed,
  signal,
} from '@angular/core';
import { BehaviorSubject, catchError, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PercentPipe } from '@angular/common';
import { StateService } from '@uirouter/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Deal } from 'src/app/deals/model/deal.model';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MessageService } from 'src/app/core/message.service';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { HeaderIndicator } from 'src/app/shared/components/chrome/form-header2/header-indicator.model';

@Injectable()
export class DealCardService {
  private _dealName = signal<string>(null);
  public dealName = computed(this._dealName);
  private _isLoading = signal<boolean>(false);
  public isLoading = computed(this._isLoading);
  private _isSaving = signal<boolean>(false);
  public isSaving = computed(this._isSaving);
  private _notSaved = signal<boolean>(false);
  public notSaved = computed(this._notSaved);

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public dealForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    organization: null,
    manager: null,
    amount: [null, [Validators.min(0)]],
    probability: [null, [Validators.max(1), Validators.min(0)]],
    resolution: null,
    source: null,
    stateId: null,
  });
  public deal: Deal;
  public baseCurrencyCode: string;

  public readonly dealCollection = this.dataService.collection('Deals');

  constructor(
    @Inject('entityId')
    public readonly dealId: string,
    private readonly actionPanelService: ActionPanelService,
    private readonly appService: AppService,
    private readonly customFieldService: CustomFieldService,
    private readonly dataService: DataService,
    private readonly destroyRef: DestroyRef,
    private readonly fb: UntypedFormBuilder,
    private readonly formHeaderService: FormHeaderService,
    private readonly lifecycleService: LifecycleService,
    private readonly messageService: MessageService,
    private readonly navigationService: NavigationService,
    private readonly notificationService: NotificationService,
    private readonly percentPipe: PercentPipe,
    private readonly savingQueueService: SavingQueueService,
    private readonly stateService: StateService,
    private readonly wpCurrencyPipe: WpCurrencyPipe,
  ) {
    this.lifecycleService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load();
    });
    this.actionPanelService.reset();
    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.reload();
    });
    this.actionPanelService.setAdditional([
      {
        name: 'delete',
        title: 'components.dealCardComponent.actions.delete',
        hint: 'components.dealCardComponent.actions.delete',
        isBusy: false,
        isVisible: this.appService.checkEntityPermission(
          'Deal',
          PermissionType.Delete,
        ),
        handler: () => this.deleteDeal(),
      },
    ]);

    this.customFieldService.enrichFormGroup(this.dealForm, 'Deal');
    this.baseCurrencyCode =
      this.appService.session.configuration.baseCurrencyCode;
    this.subscribeToFormChanges();

    this.savingQueueService.save$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.dealForm.markAsPristine();
      this._notSaved.set(false);
    });
    this.savingQueueService.error$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.reload();
    });
  }

  /** Saves deal's name. */
  public saveName = (name: string) =>
    this.dealCollection
      .entity(this.dealId)
      .patch({ name })
      .pipe(
        catchError((error: Exception) => {
          this.notificationService.error(error.message);
          return null;
        }),
        tap(() => {
          this.deal.name = name;
          this.dealForm.patchValue({ name }, { emitEvent: false });
        }),
      );

  /** Loads deal. */
  public load(): void {
    this.savingQueueService.save().then(
      () => {
        this.dealForm.markAsPristine();
        this.dealForm.markAsUntouched();

        this._isLoading.set(true);

        const query = {
          select: [
            'name',
            'editAllowed',
            'description',
            'id',
            'amount',
            'probability',
          ],
          expand: [
            { organization: { select: ['id', 'name'] } },
            { manager: { select: ['id', 'name'] } },
            { source: { select: ['id', 'name'] } },
            { state: { select: ['id', 'name'] } },
            { resolution: { select: ['id', 'name'] } },
            {
              dealContacts: {
                select: ['contactId'],
              },
            },
          ],
        };

        this.customFieldService.enrichQuery(query, 'Deal');

        this.dealCollection
          .entity(this.dealId)
          .get<Deal>(query)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (deal: Deal) => {
              this.dealForm.patchValue(deal, { emitEvent: false });
              this.deal = { ...deal };
              this._isLoading.set(false);
              deal.editAllowed
                ? this.dealForm.enable({ emitEvent: false })
                : this.dealForm.disable({ emitEvent: false });
              this._dealName.set(deal.name);
              this.updateIndicators();
              this.state$.next(CardState.Ready);
              this.navigationService.addRouteSegment({
                id: this.dealId,
                title: deal.name,
              });
            },
            error: (error: Exception) => {
              this._isLoading.set(false);
              this.state$.next(CardState.Error);
              this.notificationService.error(error.message);
            },
          });
      },
      () => null,
    );
  }

  /** Deletes current deal. */
  private deleteDeal(): void {
    this.messageService
      .confirmLocal('shared2.messages.deleteConfirmation')
      .then(
        () => {
          this.dealCollection
            .entity(this.dealId)
            .delete()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => {
                this.notificationService.successLocal(
                  'shared2.messages.deleteCompleted',
                );
                const selectedNavigationItem =
                  this.navigationService.selectedNavigationItem;
                this.stateService.go(
                  selectedNavigationItem?.state ?? 'deals',
                  selectedNavigationItem?.stateParams,
                );
              },
              error: (error: Exception) => {
                this.notificationService.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  /** Saving when changing the form. */
  private subscribeToFormChanges(): void {
    this.dealForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.dealForm.invalid) {
          this._notSaved.set(true);
          return;
        }

        const dealFormValue = this.dealForm.getRawValue();

        this.deal = Object.assign({}, this.deal, dealFormValue, {
          organizationId: dealFormValue.organization?.id ?? null,
          managerId: dealFormValue.manager?.id || null,
          resolutionId: dealFormValue.resolution?.id || null,
          sourceId: dealFormValue.source?.id || null,
          stateId: this.deal.state?.id || null,
        });

        this._dealName.set(this.deal.name);
        this.updateIndicators();

        this.savingQueueService.addToQueue(
          this.dealId,
          this.dealCollection.entity(this.dealId).update(this.deal),
        );
      });
  }

  /** Reloads deal's data. */
  private reload(): void {
    this.savingQueueService.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  /** Updates indicators. */
  private updateIndicators(): void {
    const indicators: HeaderIndicator[] = [];
    if (this.deal.amount !== undefined && this.deal.amount !== null) {
      indicators.push({
        description: 'shared2.props.amount',
        value: this.wpCurrencyPipe.transform(
          this.deal.amount,
          this.baseCurrencyCode,
        ),
      });
    }
    if (this.deal.probability !== undefined && this.deal.probability !== null) {
      indicators.push({
        description: 'shared2.props.probability',
        value: this.percentPipe.transform(this.deal.probability, '1.0-2'),
      });
    }
    this.formHeaderService.updateIndicators(indicators);
  }
}
