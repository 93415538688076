import {
  DestroyRef,
  Inject,
  inject,
  Injectable,
  Injector,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { filter, merge, Observable } from 'rxjs';
import { Collection, DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { Constants } from 'src/app/shared/globals/constants';

import { Exception } from 'src/app/shared/models/exception';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { Contact } from 'src/app/contacts/model/contact.model';
import { ClientContactsToolbarComponent } from 'src/app/clients/card/client-contact/toolbar/client-contacts-toolbar.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from 'src/app/shared/models/entities/projects/client.model';
import { Order } from 'src/app/shared/models/inner/order';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { ContactCreateModalComponent } from 'src/app/contacts/contact-create/contact-create.component';

@Injectable()
export class ClientContactsService {
  public formArray: UntypedFormArray;
  public gridOptions: GridOptions = {
    resizableColumns: true,
    sorting: true,
    selectionType: SelectionType.row,
    toolbar: ClientContactsToolbarComponent,
    commands: [
      {
        name: 'create',
        allowedFn: () => !this.readonly(),
        handlerFn: () => {
          this.createClientContact();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly() && !!formGroup,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteContact(formGroup.value.id);
        },
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly(),
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteContact(formGroup.value.id),
      },
    ],
  };
  /** Set whether to display only active contacts */
  public onlyActive = signal<boolean>(true);

  protected readonly = signal<boolean>(false);

  /** Prevents client contact autosaving. */
  private isStopSaving = signal<boolean>(false);
  private contactCollection: Collection;

  protected readonly dataService = inject(DataService);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly injector = inject(Injector);
  protected readonly notificationService = inject(NotificationService);
  protected readonly modal = inject(NgbModal);
  protected readonly listService = inject(ListService);

  private readonly customFieldService = inject(CustomFieldService);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly gridService = inject(GridService);
  private readonly savingQueueService = inject(SavingQueueService);

  constructor(@Inject('organizationId') public organizationId: string) {
    this.contactCollection = this.dataService.collection('Contacts');
    this.formArray = this.fb.array([]);
    this.gridOptions.view = this.listService.getGridView();

    this.savingQueueService.delayDuration = 0;
    this.gridService.setOrder(this.listService.getGridView().order);
    this.gridService.order$
      .pipe(takeUntilDestroyed())
      .subscribe((o: Order) => this.orderChanged(o));
  }

  /**
   * Loads client contacts.
   *
   * @param queryExtras extra query params
   */
  public load(queryExtras: any = {}): void {
    this.savingQueueService.save().then(
      () => {
        this.gridService.setLoadingState(true);
        this.formArray.clear();

        const query = this.buildContactsQuery();
        Object.assign(query.expand.contacts, {
          filter: {
            ...query.expand.contacts.filter,
            ...queryExtras.filter,
          },
        });

        if (!Object.keys(query.expand.contacts.filter).length) {
          delete query.expand.contacts.filter;
        }

        this.dataService
          .collection('Organizations')
          .entity(this.organizationId)
          .get(query)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (client: Partial<Client>) => {
              this.processContacts(client.contacts, client.contactsEditAllowed);
              this.gridService.setLoadingState(false);
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
              this.gridService.setLoadingState(false);
            },
          });
      },
      () => null,
    );
  }

  /** Adding a new row to the contacts table. */
  protected createClientContact(): void {
    const modalRef = this.modal.open(ContactCreateModalComponent, {
      injector: this.injector,
    });

    (modalRef.componentInstance as ContactCreateModalComponent).organizationId =
      this.organizationId;

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Deletes contact.
   *
   * @param contactId id of deleting contact.
   */
  protected deleteContact(contactId: string): void {
    this.contactCollection
      .entity(contactId)
      .delete()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.onContactDeleted(contactId);
          this.notificationService.successLocal('shared.deleteCompleted');
        },
        error: (error: Exception) =>
          this.notificationService.error(error.message),
      });
  }

  /**
   * Handles post-deletion logic for a contact.
   *
   * @param contactId id of deleting contact.
   */
  protected onContactDeleted(contactId: string): void {
    const index = this.formArray.controls.findIndex(
      (control) => control.value.id === contactId,
    );
    if (index > -1) {
      this.formArray.controls.splice(index, 1);
    }
    const groupForSelection =
      this.formArray.controls[index] ??
      this.formArray.controls[index - 1] ??
      null;

    this.gridService.selectGroup(groupForSelection as UntypedFormGroup);
    this.gridService.detectChanges();
  }

  /** Opens view configuration dialog. */
  protected setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }

  /**
   * Gets orderBy for loading.
   *
   * @returns orderBy string.
   */
  private getOrderBy(): string {
    const column = this.gridService.order.column || 'firstName';
    const direction = this.gridService.order.reverse ? 'desc' : 'asc';

    return `${column} ${direction}`;
  }

  /**
   * Processes loaded contacts and updates the form array.
   *
   * @param contacts loaded contacts.
   * @param contactsEditAllowed indicator weather or not contacts can be edited.
   */
  private processContacts(
    contacts: Contact[],
    contactsEditAllowed: boolean,
  ): void {
    contacts.forEach((contact: Contact) => {
      const group = this.getClientContactFormGroup();
      group.patchValue(contact, { emitEvent: false });
      if (!contactsEditAllowed) {
        this.readonly.set(true);
        group.disable({ emitEvent: false });
      }
      this.formArray.push(group);
    });
  }

  /**
   * Builds OData contacts query.
   * @returns OData contacts query object.
   */
  private buildContactsQuery(): any {
    const query: any = {
      select: ['id', 'editAllowed', 'contactsEditAllowed'],
      expand: {
        contacts: {
          select: [
            'id',
            'name',
            'firstName',
            'lastName',
            'patronymic',
            'organizationId',
            'roleId',
            'position',
            'description',
            'email',
            'mobilePhone',
            'phone',
          ],
          filter: {},
          orderBy: this.getOrderBy(),
        },
      },
    };

    this.customFieldService.enrichQuery(query.expand.contacts, 'Contact');

    if (this.onlyActive()) {
      query.expand.contacts.filter.isActive = true;
    }

    return query;
  }

  /**
   * Returns contact form group.
   *
   * @returns contact form group.
   */
  private getClientContactFormGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: null,
      name: [
        null,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      organizationId: null,
      roleId: null,
      position: null,
      description: null,
      email: null,
      mobilePhone: null,
      phone: null,
      firstName: [
        null,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      lastName: null,
      patronymic: null,
    });

    this.customFieldService.enrichFormGroup(group, 'Contact');

    merge(
      group.controls.firstName.valueChanges,
      group.controls.lastName.valueChanges,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isStopSaving.set(true);
        group.controls.name.setValue(
          `${group.controls.firstName.value} ${group.controls.lastName.value}`,
        );
        this.isStopSaving.set(false);
      });

    group.valueChanges
      .pipe(
        filter(() => !this.isStopSaving()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (group.invalid) {
          this.notificationService.warningLocal(
            'shared2.messages.entityNotSaved',
          );
          return;
        }

        this.savingQueueService.addToQueue(
          group.value.id,
          this.editClientContact(group.getRawValue()),
        );
      });

    return group;
  }

  /**
   * Generates an update request for a client contacts.
   *
   * @param contact The project cost center to be updated.
   * @returns An observable of the updated client contact.
   */
  private editClientContact(contact: Contact): Observable<Contact> {
    contact.organizationId = this.organizationId;

    return this.contactCollection
      .entity(contact.id)
      .update(contact)
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  /**
   * Updates the order of the contact view and reloads the list.
   *
   * @param order The new order to be applied.
   */
  private orderChanged(order: Order): void {
    const userView = this.listService.getUserView();
    userView.order = order;
    this.listService.saveUserView(userView);
    this.load();
  }
}
