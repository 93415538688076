import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { DealsListComponent } from 'src/app/deals/list/deal-list.component';
import { DealCreateModalComponent } from 'src/app/deals/creation/deal-create-modal.component';
import { DealCardComponent } from 'src/app/deals/card/deal-card.component';
import { DealProfileComponent } from 'src/app/deals/card/deal-profile/deal-profile.component';
import { DEALS_LIST } from 'src/app/deals/model/deal.list';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { InteractionsComponent } from 'src/app/shared/modules/interactions/interactions.component';
import { InteractionsModule } from 'src/app/shared/modules/interactions/interactions.module';
import { ClientsModule } from 'src/app/clients/clients.module';
import { DealContactsComponent } from 'src/app/deals/card/deal-contacts/deal-contacts.component';
import { DealContactsToolbarComponent } from 'src/app/deals/card/deal-contacts/toolbar/deal-contacts-toolbar.component';

@NgModule({
  declarations: [
    DealsListComponent,
    DealCreateModalComponent,
    DealCardComponent,
    DealProfileComponent,
    DealContactsComponent,
    DealContactsToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    InteractionsModule,
    ClientsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'deals',
          url: '/deals?{navigation}&{route}',
          component: DealsListComponent,
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: DEALS_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'deal',
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'deal',
          params: {
            navigation: 'clients.deals',
          },
          url: '/deals/{entityId:guid}',
          component: DealCardComponent,
          redirectTo: 'deal.profile',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'deal.profile',
          url: '/profile',
          component: DealProfileComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'deal.interactions',
          url: '/interactions',
          component: InteractionsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class DealsModule {}
